<script lang="ts" setup>
import * as yup from 'yup'

import Sidebar from 'primevue/sidebar'
import OverlayPanel from 'primevue/overlaypanel'

import { useAuthStore } from '@/stores/authentication'
import { reactive, ref } from 'vue'
import { LockClosedIcon } from '@heroicons/vue/24/outline'
import { useForm } from 'vee-validate'
import { useUpdateProfile } from '@/services/account/useProfileUpdate'
import { VTour } from '@globalhive/vuejs-tour'

const authStore = useAuthStore()

// Context
const dropdownContext = reactive({
  industry: [
    'Banking, Financial Services & Insurance (BFSI)',
    'Business Consulting & Services',
    'Education',
    'Hospital & Healthcare',
    'Human Resources',
    'Information Technology/IT Enabled Services',
    'IT Security',
    'Legal',
    'Packaging & Containers',
    'Print & Publishing',
    'Other'
  ],
  phoneNumberCode: [
    {
      country: 'Ascension',
      alpha2CountryCode: 'AC',
      stdCode: '+247'
    },
    {
      country: 'Andorra',
      alpha2CountryCode: 'AD',
      stdCode: '+376'
    },
    {
      country: 'United Arab Emirates',
      alpha2CountryCode: 'AE',
      stdCode: '+971'
    },
    {
      country: 'Antigua and Barbuda',
      alpha2CountryCode: 'AG',
      stdCode: '+1-268'
    },
    {
      country: 'Anguilla',
      alpha2CountryCode: 'AI',
      stdCode: '+1-264'
    },
    {
      country: 'Albania',
      alpha2CountryCode: 'AL',
      stdCode: '+355'
    },
    {
      country: 'Armenia',
      alpha2CountryCode: 'AM',
      stdCode: '+374'
    },
    {
      country: 'Angola',
      alpha2CountryCode: 'AO',
      stdCode: '+244'
    },
    {
      country: 'Antarctica',
      alpha2CountryCode: 'AQ',
      stdCode: '+672'
    },
    {
      country: 'Argentina',
      alpha2CountryCode: 'AR',
      stdCode: '+54'
    },
    {
      country: 'American Samoa',
      alpha2CountryCode: 'AS',
      stdCode: '+1-684'
    },
    {
      country: 'Austria',
      alpha2CountryCode: 'AT',
      stdCode: '+43'
    },
    {
      country: 'Australia',
      alpha2CountryCode: 'AU',
      stdCode: '+61'
    },
    {
      country: 'Aruba',
      alpha2CountryCode: 'AW',
      stdCode: '+297'
    },
    {
      country: 'Azerbaijan',
      alpha2CountryCode: 'AZ',
      stdCode: '+994'
    },
    {
      country: 'Bosnia & Herzegovina',
      alpha2CountryCode: 'BA',
      stdCode: '+387'
    },
    {
      country: 'Barbados',
      alpha2CountryCode: 'BB',
      stdCode: '+1-246'
    },
    {
      country: 'Bangladesh',
      alpha2CountryCode: 'BD',
      stdCode: '+880'
    },
    {
      country: 'Belgium',
      alpha2CountryCode: 'BE',
      stdCode: '+32'
    },
    {
      country: 'Burkina Faso',
      alpha2CountryCode: 'BF',
      stdCode: '+226'
    },
    {
      country: 'Bulgaria',
      alpha2CountryCode: 'BG',
      stdCode: '+359'
    },
    {
      country: 'Bahrain',
      alpha2CountryCode: 'BH',
      stdCode: '+973'
    },
    {
      country: 'Burundi',
      alpha2CountryCode: 'BI',
      stdCode: '+257'
    },
    {
      country: 'Benin',
      alpha2CountryCode: 'BJ',
      stdCode: '+229'
    },
    {
      country: 'Bermuda',
      alpha2CountryCode: 'BM',
      stdCode: '+1-441'
    },
    {
      country: 'Brunei Darussalam',
      alpha2CountryCode: 'BN',
      stdCode: '+673'
    },
    {
      country: 'Bolivia',
      alpha2CountryCode: 'BO',
      stdCode: '+591'
    },
    {
      country: 'Brazil',
      alpha2CountryCode: 'BR',
      stdCode: '+55'
    },
    {
      country: 'Bahamas',
      alpha2CountryCode: 'BS',
      stdCode: '+1-242'
    },
    {
      country: 'Bhutan',
      alpha2CountryCode: 'BT',
      stdCode: '+975'
    },
    {
      country: 'Botswana',
      alpha2CountryCode: 'BW',
      stdCode: '+267'
    },
    {
      country: 'Belarus',
      alpha2CountryCode: 'BY',
      stdCode: '+375'
    },
    {
      country: 'Belize',
      alpha2CountryCode: 'BZ',
      stdCode: '+501'
    },
    {
      country: 'Canada',
      alpha2CountryCode: 'CA',
      stdCode: '+1'
    },
    {
      country: 'Cocos-Keeling Islands',
      alpha2CountryCode: 'CC',
      stdCode: '+61'
    },
    {
      country: 'Democratic Republic of the Congo',
      alpha2CountryCode: 'CD',
      stdCode: '+243'
    },
    {
      country: 'Central African Republic',
      alpha2CountryCode: 'CF',
      stdCode: '+236'
    },
    {
      country: 'Congo',
      alpha2CountryCode: 'CG',
      stdCode: '+242'
    },
    {
      country: 'Switzerland',
      alpha2CountryCode: 'CH',
      stdCode: '+41'
    },
    {
      country: "Côte d'Ivoire (Ivory Coast)",
      alpha2CountryCode: 'CI',
      stdCode: '+225'
    },
    {
      country: 'Cook Islands',
      alpha2CountryCode: 'CK',
      stdCode: '+682'
    },
    {
      country: 'Chile',
      alpha2CountryCode: 'CL',
      stdCode: '+56'
    },
    {
      country: 'Cameroon',
      alpha2CountryCode: 'CM',
      stdCode: '+237'
    },
    {
      country: 'China',
      alpha2CountryCode: 'CN',
      stdCode: '+86'
    },
    {
      country: 'Colombia',
      alpha2CountryCode: 'CO',
      stdCode: '+57'
    },
    {
      country: 'Costa Rica',
      alpha2CountryCode: 'CR',
      stdCode: '+506'
    },
    {
      country: 'Cuba (Guantanamo Bay)',
      alpha2CountryCode: 'CU',
      stdCode: '+5399'
    },
    {
      country: 'Cuba',
      alpha2CountryCode: 'CU',
      stdCode: '+53'
    },
    {
      country: 'Cape Verde Islands',
      alpha2CountryCode: 'CV',
      stdCode: '+238'
    },
    {
      country: 'Curaçao',
      alpha2CountryCode: 'CW',
      stdCode: '+599'
    },
    {
      country: 'Christmas Island',
      alpha2CountryCode: 'CX',
      stdCode: '+61'
    },
    {
      country: 'Cyprus',
      alpha2CountryCode: 'CY',
      stdCode: '+357'
    },
    {
      country: 'Czech Republic',
      alpha2CountryCode: 'CZ',
      stdCode: '+420'
    },
    {
      country: 'Germany',
      alpha2CountryCode: 'DE',
      stdCode: '+49'
    },
    {
      country: 'Djibouti',
      alpha2CountryCode: 'DJ',
      stdCode: '+253'
    },
    {
      country: 'Denmark',
      alpha2CountryCode: 'DK',
      stdCode: '+45'
    },
    {
      country: 'Dominica',
      alpha2CountryCode: 'DM',
      stdCode: '+1-767'
    },
    {
      country: 'Dominican Republic',
      alpha2CountryCode: 'DO',
      stdCode: '+1-809'
    },
    {
      country: 'Dominican Republic',
      alpha2CountryCode: 'DO',
      stdCode: '+1-829'
    },
    {
      country: 'Algeria',
      alpha2CountryCode: 'DZ',
      stdCode: '+213'
    },
    {
      country: 'Ecuador',
      alpha2CountryCode: 'EC',
      stdCode: '+593'
    },
    {
      country: 'Estonia',
      alpha2CountryCode: 'EE',
      stdCode: '+372'
    },
    {
      country: 'Egypt',
      alpha2CountryCode: 'EG',
      stdCode: '+20'
    },
    {
      country: 'Eritrea',
      alpha2CountryCode: 'ER',
      stdCode: '+291'
    },
    {
      country: 'Spain',
      alpha2CountryCode: 'ES',
      stdCode: '+34'
    },
    {
      country: 'Ethiopia',
      alpha2CountryCode: 'ET',
      stdCode: '+251'
    },
    {
      country: 'Finland',
      alpha2CountryCode: 'FI',
      stdCode: '+358'
    },
    {
      country: 'Fiji Islands',
      alpha2CountryCode: 'FJ',
      stdCode: '+679'
    },
    {
      country: 'Falkland Islands (Malvinas)',
      alpha2CountryCode: 'FK',
      stdCode: '+500'
    },
    {
      country: 'Micronesia, (Federal States of)',
      alpha2CountryCode: 'FM',
      stdCode: '+691'
    },
    {
      country: 'Faroe Islands',
      alpha2CountryCode: 'FO',
      stdCode: '+298'
    },
    {
      country: 'France',
      alpha2CountryCode: 'FR',
      stdCode: '+33'
    },
    {
      country: 'Gabonese Republic',
      alpha2CountryCode: 'GA',
      stdCode: '+241'
    },
    {
      country: 'United Kingdom',
      alpha2CountryCode: 'GB',
      stdCode: '+44'
    },
    {
      country: 'Grenada',
      alpha2CountryCode: 'GD',
      stdCode: '+1-473'
    },
    {
      country: 'Georgia',
      alpha2CountryCode: 'GE',
      stdCode: '+995'
    },
    {
      country: 'French Guiana',
      alpha2CountryCode: 'GF',
      stdCode: '+594'
    },
    {
      country: 'Ghana',
      alpha2CountryCode: 'GH',
      stdCode: '+233'
    },
    {
      country: 'Gibraltar',
      alpha2CountryCode: 'GI',
      stdCode: '+350'
    },
    {
      country: 'Greenland',
      alpha2CountryCode: 'GL',
      stdCode: '+299'
    },
    {
      country: 'Gambia',
      alpha2CountryCode: 'GM',
      stdCode: '+220'
    },
    {
      country: 'Guinea',
      alpha2CountryCode: 'GN',
      stdCode: '+224'
    },
    {
      country: 'Guadeloupe',
      alpha2CountryCode: 'GP',
      stdCode: '+590'
    },
    {
      country: 'Equatorial Guinea',
      alpha2CountryCode: 'GQ',
      stdCode: '+240'
    },
    {
      country: 'Greece',
      alpha2CountryCode: 'GR',
      stdCode: '+30'
    },
    {
      country: 'Guatemala',
      alpha2CountryCode: 'GT',
      stdCode: '+502'
    },
    {
      country: 'Guam',
      alpha2CountryCode: 'GU',
      stdCode: '+1-671'
    },
    {
      country: 'Guinea-Bissau',
      alpha2CountryCode: 'GW',
      stdCode: '+245'
    },
    {
      country: 'Guyana',
      alpha2CountryCode: 'GY',
      stdCode: '+592'
    },
    {
      country: 'Hong Kong',
      alpha2CountryCode: 'HK',
      stdCode: '+852'
    },
    {
      country: 'Honduras',
      alpha2CountryCode: 'HN',
      stdCode: '+504'
    },
    {
      country: 'Croatia',
      alpha2CountryCode: 'HR',
      stdCode: '+385'
    },
    {
      country: 'Haiti',
      alpha2CountryCode: 'HT',
      stdCode: '+509'
    },
    {
      country: 'Hungary',
      alpha2CountryCode: 'HU',
      stdCode: '+36'
    },
    {
      country: 'Indonesia',
      alpha2CountryCode: 'ID',
      stdCode: '+62'
    },
    {
      country: 'Ireland',
      alpha2CountryCode: 'IE',
      stdCode: '+353'
    },
    {
      country: 'Israel',
      alpha2CountryCode: 'IL',
      stdCode: '+972'
    },
    {
      country: 'India',
      alpha2CountryCode: 'IN',
      stdCode: '+91'
    },
    {
      country: 'Iraq',
      alpha2CountryCode: 'IQ',
      stdCode: '+964'
    },
    {
      country: 'Iran',
      alpha2CountryCode: 'IR',
      stdCode: '+98'
    },
    {
      country: 'Iceland',
      alpha2CountryCode: 'IS',
      stdCode: '+354'
    },
    {
      country: 'Italy',
      alpha2CountryCode: 'IT',
      stdCode: '+39'
    },
    {
      country: 'Jamaica',
      alpha2CountryCode: 'JM',
      stdCode: '+1-876'
    },
    {
      country: 'Jordan',
      alpha2CountryCode: 'JO',
      stdCode: '+962'
    },
    {
      country: 'Japan',
      alpha2CountryCode: 'JP',
      stdCode: '+81'
    },
    {
      country: 'Kenya',
      alpha2CountryCode: 'KE',
      stdCode: '+254'
    },
    {
      country: 'Kyrgyz Republic',
      alpha2CountryCode: 'KG',
      stdCode: '+996'
    },
    {
      country: 'Cambodia',
      alpha2CountryCode: 'KH',
      stdCode: '+855'
    },
    {
      country: 'Kiribati',
      alpha2CountryCode: 'KI',
      stdCode: '+686'
    },
    {
      country: 'Comoros',
      alpha2CountryCode: 'KM',
      stdCode: '+269'
    },
    {
      country: 'St. Kitts/Nevis',
      alpha2CountryCode: 'KN',
      stdCode: '+1-869'
    },
    {
      country: 'Korea (North)',
      alpha2CountryCode: 'KP',
      stdCode: '+850'
    },
    {
      country: 'Korea (South)',
      alpha2CountryCode: 'KR',
      stdCode: '+82'
    },
    {
      country: 'Kuwait',
      alpha2CountryCode: 'KW',
      stdCode: '+965'
    },
    {
      country: 'Cayman Islands',
      alpha2CountryCode: 'KY',
      stdCode: '+1-345'
    },
    {
      country: 'Kazakhstan',
      alpha2CountryCode: 'KZ',
      stdCode: '+7'
    },
    {
      country: 'Laos',
      alpha2CountryCode: 'LA',
      stdCode: '+856'
    },
    {
      country: 'Lebanon',
      alpha2CountryCode: 'LB',
      stdCode: '+961'
    },
    {
      country: 'St. Lucia',
      alpha2CountryCode: 'LC',
      stdCode: '+1-758'
    },
    {
      country: 'Liechtenstein',
      alpha2CountryCode: 'LI',
      stdCode: '+423'
    },
    {
      country: 'Sri Lanka',
      alpha2CountryCode: 'LK',
      stdCode: '+94'
    },
    {
      country: 'Liberia',
      alpha2CountryCode: 'LR',
      stdCode: '+231'
    },
    {
      country: 'Lesotho',
      alpha2CountryCode: 'LS',
      stdCode: '+266'
    },
    {
      country: 'Lithuania',
      alpha2CountryCode: 'LT',
      stdCode: '+370'
    },
    {
      country: 'Luxembourg',
      alpha2CountryCode: 'LU',
      stdCode: '+352'
    },
    {
      country: 'Latvia',
      alpha2CountryCode: 'LV',
      stdCode: '+371'
    },
    {
      country: 'Libya',
      alpha2CountryCode: 'LY',
      stdCode: '+218'
    },
    {
      country: 'Morocco',
      alpha2CountryCode: 'MA',
      stdCode: '+212'
    },
    {
      country: 'Monaco',
      alpha2CountryCode: 'MC',
      stdCode: '+377'
    },
    {
      country: 'Moldova',
      alpha2CountryCode: 'MD',
      stdCode: '+373'
    },
    {
      country: 'Montenegro',
      alpha2CountryCode: 'ME',
      stdCode: '+382'
    },
    {
      country: 'Madagascar',
      alpha2CountryCode: 'MG',
      stdCode: '+261'
    },
    {
      country: 'Marshall Islands',
      alpha2CountryCode: 'MH',
      stdCode: '+692'
    },
    {
      country: 'Macedonia (Former Yugoslav Rep of.)',
      alpha2CountryCode: 'MK',
      stdCode: '+389'
    },
    {
      country: 'Mali Republic',
      alpha2CountryCode: 'ML',
      stdCode: '+223'
    },
    {
      country: 'Myanmar',
      alpha2CountryCode: 'MM',
      stdCode: '+95'
    },
    {
      country: 'Mongolia',
      alpha2CountryCode: 'MN',
      stdCode: '+976'
    },
    {
      country: 'Macao',
      alpha2CountryCode: 'MO',
      stdCode: '+853'
    },
    {
      country: 'Northern Marianas Islands (Saipan, Rota, & Tinian)',
      alpha2CountryCode: 'MP',
      stdCode: '+1-670'
    },
    {
      country: 'Martinique',
      alpha2CountryCode: 'MQ',
      stdCode: '+596'
    },
    {
      country: 'Mauritania',
      alpha2CountryCode: 'MR',
      stdCode: '+222'
    },
    {
      country: 'Montserrat',
      alpha2CountryCode: 'MS',
      stdCode: '+1-664'
    },
    {
      country: 'Malta',
      alpha2CountryCode: 'MT',
      stdCode: '+356'
    },
    {
      country: 'Mauritius',
      alpha2CountryCode: 'MU',
      stdCode: '+230'
    },
    {
      country: 'Maldives',
      alpha2CountryCode: 'MV',
      stdCode: '+960'
    },
    {
      country: 'Malawi',
      alpha2CountryCode: 'MW',
      stdCode: '+265'
    },
    {
      country: 'Mexico',
      alpha2CountryCode: 'MX',
      stdCode: '+52'
    },
    {
      country: 'Malaysia',
      alpha2CountryCode: 'MY',
      stdCode: '+60'
    },
    {
      country: 'Mozambique',
      alpha2CountryCode: 'MZ',
      stdCode: '+258'
    },
    {
      country: 'Namibia',
      alpha2CountryCode: 'NA',
      stdCode: '+264'
    },
    {
      country: 'New Caledonia',
      alpha2CountryCode: 'NC',
      stdCode: '+687'
    },
    {
      country: 'Niger',
      alpha2CountryCode: 'NE',
      stdCode: '+227'
    },
    {
      country: 'Norfolk Island',
      alpha2CountryCode: 'NF',
      stdCode: '+672'
    },
    {
      country: 'Nigeria',
      alpha2CountryCode: 'NG',
      stdCode: '+234'
    },
    {
      country: 'Nicaragua',
      alpha2CountryCode: 'NI',
      stdCode: '+505'
    },
    {
      country: 'Netherlands',
      alpha2CountryCode: 'NL',
      stdCode: '+31'
    },
    {
      country: 'Norway',
      alpha2CountryCode: 'NO',
      stdCode: '+47'
    },
    {
      country: 'Nepal',
      alpha2CountryCode: 'NP',
      stdCode: '+977'
    },
    {
      country: 'Nauru',
      alpha2CountryCode: 'NR',
      stdCode: '+674'
    },
    {
      country: 'Niue',
      alpha2CountryCode: 'NU',
      stdCode: '+683'
    },
    {
      country: 'New Zealand',
      alpha2CountryCode: 'NZ',
      stdCode: '+64'
    },
    {
      country: 'Oman',
      alpha2CountryCode: 'OM',
      stdCode: '+968'
    },
    {
      country: 'Panama',
      alpha2CountryCode: 'PA',
      stdCode: '+507'
    },
    {
      country: 'Peru',
      alpha2CountryCode: 'PE',
      stdCode: '+51'
    },
    {
      country: 'French Polynesia',
      alpha2CountryCode: 'PF',
      stdCode: '+689'
    },
    {
      country: 'Papua New Guinea',
      alpha2CountryCode: 'PG',
      stdCode: '+675'
    },
    {
      country: 'Philippines',
      alpha2CountryCode: 'PH',
      stdCode: '+63'
    },
    {
      country: 'Pakistan',
      alpha2CountryCode: 'PK',
      stdCode: '+92'
    },
    {
      country: 'Poland',
      alpha2CountryCode: 'PL',
      stdCode: '+48'
    },
    {
      country: 'St. Pierre & Miquelon',
      alpha2CountryCode: 'PM',
      stdCode: '+508'
    },
    {
      country: 'Puerto Rico',
      alpha2CountryCode: 'PR',
      stdCode: '+1-787'
    },
    {
      country: 'Puerto Rico',
      alpha2CountryCode: 'PR',
      stdCode: '+1-939'
    },
    {
      country: 'Palestinian Settlements',
      alpha2CountryCode: 'PS',
      stdCode: '+970'
    },
    {
      country: 'Portugal',
      alpha2CountryCode: 'PT',
      stdCode: '+351'
    },
    {
      country: 'Palau',
      alpha2CountryCode: 'PW',
      stdCode: '+680'
    },
    {
      country: 'Paraguay',
      alpha2CountryCode: 'PY',
      stdCode: '+595'
    },
    {
      country: 'Qatar',
      alpha2CountryCode: 'QA',
      stdCode: '+974'
    },
    {
      country: 'Réunion Island',
      alpha2CountryCode: 'RE',
      stdCode: '+262'
    },
    {
      country: 'Romania',
      alpha2CountryCode: 'RO',
      stdCode: '+40'
    },
    {
      country: 'Serbia',
      alpha2CountryCode: 'RS',
      stdCode: '+381'
    },
    {
      country: 'Russia',
      alpha2CountryCode: 'RU',
      stdCode: '+7'
    },
    {
      country: 'Rwandese Republic',
      alpha2CountryCode: 'RW',
      stdCode: '+250'
    },
    {
      country: 'Saudi Arabia',
      alpha2CountryCode: 'SA',
      stdCode: '+966'
    },
    {
      country: 'Solomon Islands',
      alpha2CountryCode: 'SB',
      stdCode: '+677'
    },
    {
      country: 'Seychelles Republic',
      alpha2CountryCode: 'SC',
      stdCode: '+248'
    },
    {
      country: 'Sudan',
      alpha2CountryCode: 'SD',
      stdCode: '+249'
    },
    {
      country: 'Sweden',
      alpha2CountryCode: 'SE',
      stdCode: '+46'
    },
    {
      country: 'Singapore',
      alpha2CountryCode: 'SG',
      stdCode: '+65'
    },
    {
      country: 'St. Helena',
      alpha2CountryCode: 'SH',
      stdCode: '+290'
    },
    {
      country: 'Slovenia',
      alpha2CountryCode: 'SI',
      stdCode: '+386'
    },
    {
      country: 'Slovak Republic',
      alpha2CountryCode: 'SK',
      stdCode: '+421'
    },
    {
      country: 'Sierra Leone',
      alpha2CountryCode: 'SL',
      stdCode: '+232'
    },
    {
      country: 'San Marino',
      alpha2CountryCode: 'SM',
      stdCode: '+378'
    },
    {
      country: 'Senegal',
      alpha2CountryCode: 'SN',
      stdCode: '+221'
    },
    {
      country: 'Somalia',
      alpha2CountryCode: 'SO',
      stdCode: '+252'
    },
    {
      country: 'Suriname',
      alpha2CountryCode: 'SR',
      stdCode: '+597'
    },
    {
      country: 'São Tomé and Principe',
      alpha2CountryCode: 'ST',
      stdCode: '+239'
    },
    {
      country: 'El Salvador',
      alpha2CountryCode: 'SV',
      stdCode: '+503'
    },
    {
      country: 'Syria',
      alpha2CountryCode: 'SY',
      stdCode: '+963'
    },
    {
      country: 'Turks and Caicos Islands',
      alpha2CountryCode: 'TC',
      stdCode: '+1-649'
    },
    {
      country: 'Chad',
      alpha2CountryCode: 'TD',
      stdCode: '+235'
    },
    {
      country: 'Togolese Republic',
      alpha2CountryCode: 'TG',
      stdCode: '+228'
    },
    {
      country: 'Thailand',
      alpha2CountryCode: 'TH',
      stdCode: '+66'
    },
    {
      country: 'Tajikistan',
      alpha2CountryCode: 'TJ',
      stdCode: '+992'
    },
    {
      country: 'Tokelau',
      alpha2CountryCode: 'TK',
      stdCode: '+690'
    },
    {
      country: 'East Timor',
      alpha2CountryCode: 'TL',
      stdCode: '+670'
    },
    {
      country: 'Turkmenistan',
      alpha2CountryCode: 'TM',
      stdCode: '+993'
    },
    {
      country: 'Tunisia',
      alpha2CountryCode: 'TN',
      stdCode: '+216'
    },
    {
      country: 'Tonga Islands',
      alpha2CountryCode: 'TO',
      stdCode: '+676'
    },
    {
      country: 'Türkiye',
      alpha2CountryCode: 'TR',
      stdCode: '+90'
    },
    {
      country: 'Trinidad & Tobago',
      alpha2CountryCode: 'TT',
      stdCode: '+1-868'
    },
    {
      country: 'Tuvalu',
      alpha2CountryCode: 'TV',
      stdCode: '+688'
    },
    {
      country: 'Taiwan',
      alpha2CountryCode: 'TW',
      stdCode: '+886'
    },
    {
      country: 'Tanzania',
      alpha2CountryCode: 'TZ',
      stdCode: '+255'
    },
    {
      country: 'Ukraine',
      alpha2CountryCode: 'UA',
      stdCode: '+380'
    },
    {
      country: 'Uganda',
      alpha2CountryCode: 'UG',
      stdCode: '+256'
    },
    {
      country: 'Midway Island',
      alpha2CountryCode: 'UM',
      stdCode: '+1-808'
    },
    {
      country: 'United States of America',
      alpha2CountryCode: 'US',
      stdCode: '+1'
    },
    {
      country: 'Uruguay',
      alpha2CountryCode: 'UY',
      stdCode: '+598'
    },
    {
      country: 'Uzbekistan',
      alpha2CountryCode: 'UZ',
      stdCode: '+998'
    },
    {
      country: 'Vatican City',
      alpha2CountryCode: 'VA',
      stdCode: '+379'
    },
    {
      country: 'St. Vincent & Grenadines',
      alpha2CountryCode: 'VC',
      stdCode: '+1-784'
    },
    {
      country: 'Venezuela',
      alpha2CountryCode: 'VE',
      stdCode: '+58'
    },
    {
      country: 'British Virgin Islands',
      alpha2CountryCode: 'VG',
      stdCode: '+1-284'
    },
    {
      country: 'US Virgin Islands',
      alpha2CountryCode: 'VI',
      stdCode: '+1-340'
    },
    {
      country: 'Vietnam',
      alpha2CountryCode: 'VN',
      stdCode: '+84'
    },
    {
      country: 'Vanuatu',
      alpha2CountryCode: 'VU',
      stdCode: '+678'
    },
    {
      country: 'Wallis and Futuna Islands',
      alpha2CountryCode: 'WF',
      stdCode: '+681'
    },
    {
      country: 'Samoa',
      alpha2CountryCode: 'WS',
      stdCode: '+685'
    },
    {
      country: 'Yemen',
      alpha2CountryCode: 'YE',
      stdCode: '+967'
    },
    {
      country: 'Mayotte Island',
      alpha2CountryCode: 'YT',
      stdCode: '+269'
    },
    {
      country: 'South Africa',
      alpha2CountryCode: 'ZA',
      stdCode: '+27'
    },
    {
      country: 'Zambia',
      alpha2CountryCode: 'ZM',
      stdCode: '+260'
    },
    {
      country: 'Zimbabwe',
      alpha2CountryCode: 'ZW',
      stdCode: '+263'
    },
    {
      country: 'Netherlands Antilles',
      alpha2CountryCode: 'AN',
      stdCode: '+599'
    },
    {
      country: 'Swaziland',
      alpha2CountryCode: 'SZ',
      stdCode: '+268'
    },
    {
      country: 'Timor Leste',
      alpha2CountryCode: 'TL',
      stdCode: '+670'
    },
    {
      country: 'Wake Island',
      alpha2CountryCode: 'WK',
      stdCode: '+808'
    },
    {
      country: 'Zanzibar',
      alpha2CountryCode: 'TZ',
      stdCode: '+255'
    },
    {
      country: 'Afghanistan',
      alpha2CountryCode: 'AF',
      stdCode: '+93'
    }
  ]
})

// Form validation
const { defineField, handleSubmit, errors, meta } = useForm({
  validationSchema: {
    industry: yup.string().required().label('Industry'),
    companyName: yup.string().label('Company name'),
    countryCode: yup.object().notRequired().default(null).label('Country code'),
    phoneNumber: yup.string().notRequired().label('Phone number')
  }
})

const [industry, industryAttr] = defineField('industry')
const [companyName, companyNameAttr] = defineField('companyName')
const [countryCode, countryCodeAttr] = defineField('countryCode')
const [phoneNumber, phoneNumberAttr] = defineField('phoneNumber')

// Tour
const tour = ref(null)
const steps = [
  {
    target: '[data-step="0"]',
    placement: 'top-end',
    content:
      '<div class="font-jost p-4 rounded-lg"><p class="font-semibold text-lg pb-2">Discover QR Mark in Minutes</p></hr><p class="text-base py-2">Ready to see how QR Mark simplifies document authentication? Let’s get you started with a quick tour.</p></div>'
  },
  {
    target: '[data-step="1"]',
    placement: 'top-end',
    content:
      '<div class="font-jost p-4 rounded-lg"><p class="font-semibold text-lg pb-2">Managing Your Verifications</p></hr>Find all your secured documents here in this section. Track and manage every document you\'ve authenticated in one place.<p class="text-base py-2"></p></div>'
  },
  {
    target: '[data-step="2"]',
    placement: 'top-end',
    content:
      '<div class="font-jost p-4 rounded-lg"><p class="font-semibold text-lg pb-2">Seamless Integration</p></hr><p class="text-base py-2">Connect QR Mark with your preferred tools. Our Google Workspace and Microsoft Office add-ons let you add verifiable QR Codes directly. No need to change your workflow.</p></div>'
  },
  {
    target: '[data-step="3"]',
    content:
      '<div class="font-jost p-4 rounded-lg"><p class="font-semibold text-lg pb-2">Save Time with Bulk Operations</p></hr><p class="text-base py-2">Need to secure multiple documents? Our bulk operation feature lets you process multiple files simultaneously, saving you valuable time.</p></div>'
  },
  {
    target: '[data-step="4"]',
    content:
      '<div class="font-jost p-4 rounded-lg"><p class="font-semibold text-lg pb-2">Complete Control</p></hr><p class="text-base py-2">This section is your command centre. Manage your custom domain, add team members, and set user access levels for complete control over your workspace.</p></div>'
  },
  {
    target: '[data-step="5"]',
    content:
      '<div class="font-jost p-4 rounded-lg"><p class="font-semibold text-lg pb-2">Manage Your Account</p></hr><p class="text-base py-2">In this section, you can view the instructions to modify your account information, including your registered email address and password.</p></div>'
  },
  {
    target: '[data-step="6"]',
    content:
      '<div class="font-jost p-4 rounded-lg"><p class="font-semibold text-lg pb-2">Always Here to Help</p></hr><p class="text-base py-2">Need assistance? Our dedicated support team is just an email away, ready to help with any questions.</p></div>'
  },
  {
    target: '[data-step="7"]',
    placement: 'bottom-end',
    content:
      '<div class="font-jost p-4 rounded-lg"><p class="font-semibold text-lg pb-2">Create Your First Verification</p></hr><p class="text-base py-2">This is where your document security begins. Upload your file, select your preferred domain, and position the verifiable QR Code using our preview tool to ensure perfect placement.</p></div>'
  }
]

// Form submit
const onHandleSubmit = handleSubmit((values) => {
  isLoading.value = true
  useUpdateProfile(
    values.industry,
    values.companyName,
    values.countryCode?.stdCode,
    values.phoneNumber
  ).then(({ status }) => {
    if (status.value == 200) authStore.setShowOnboardingScene(false)
    isLoading.value = false
    // Start Tour
    //@ts-ignore
    tour.value.startTour()
  })
})

const isLoading = ref<boolean>(false)
const opCompanyName = ref()
const opPhoneNumber = ref()

const toggleCompanyName = (event: any) => {
  opCompanyName.value.toggle(event)
}

const togglePhoneNumber = (event: any) => {
  opPhoneNumber.value.toggle(event)
}

// Sidebar Pass Through
const sidebarPT = {
  root: ({ props }: any) => ({
    class: [
      // Flexbox
      'flex flex-col',
      // Position
      'relative',
      {
        '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
          props.position == 'full'
      },
      // Size
      {
        'h-full w-5/6': props.position == 'left' || props.position == 'right',
        'h-auto w-full': props.position == 'top' || props.position == 'bottom'
      },
      // Shape
      'border-0 dark:border',
      'shadow-lg',
      // Colors
      'bg-surface-0 dark:bg-surface-800',
      'text-surface-700 dark:text-white/80',
      'dark:border-surface-700',
      // Transitions
      'transition-transform',
      'duration-300',
      // Misc
      'pointer-events-auto'
    ]
  }),
  header: {
    class: [
      'flex items-center justify-center',
      'shrink-0',
      'p-10',
      'bg-surface-0 dark:bg-surface-800',
      'text-surface-700 dark:text-surface-0/80'
    ]
  },
  title: {
    class: ['font-bold text-lg']
  },
  icons: {
    class: ['flex items-center']
  },
  closeButton: {
    class: [
      'absolute right-10',
      'flex items-center justify-center',
      'mr-2',
      'last:mr-0',
      'w-8 h-8',
      'border-2',
      'rounded-full',
      'text-surface-500',
      'bg-transparent',
      'transition duration-200 ease-in-out',
      'hover:text-surface-700 dark:hover:text-white/80',
      'hover:bg-surface-100 dark:hover:bg-surface-800/80',
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-inset',
      'focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
      'overflow-hidden'
    ]
  },
  closeButtonIcon: {
    class: ['inline-block', 'w-4', 'h-4']
  },
  content: {
    class: ['p-5', 'pt-0', 'h-full', 'w-full', 'grow', 'overflow-y-auto']
  },
  mask: ({ props }: any) => ({
    class: [
      // Transitions
      'transition-all',
      'duration-300',
      { 'p-0': props.position != 'full' },
      // Background and Effects
      {
        'has-[.mask-active]:bg-transparent bg-black/40': props.modal,
        'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': props.modal
      }
    ]
  }),
  transition: ({ props }: any) =>
    props.position === 'top'
      ? {
          enterFromClass: 'translate-x-0 -translate-y-full translate-z-0 mask-active',
          leaveToClass: 'translate-x-0 -translate-y-full translate-z-0 mask-active'
        }
      : props.position === 'bottom'
        ? {
            enterFromClass: 'translate-x-0 translate-y-full translate-z-0 mask-active',
            leaveToClass: 'translate-x-0 translate-y-full translate-z-0 mask-active'
          }
        : props.position === 'left'
          ? {
              enterFromClass: '-translate-x-full translate-y-0 translate-z-0 mask-active',
              leaveToClass: '-translate-x-full translate-y-0 translate-z-0 mask-active'
            }
          : props.position === 'right'
            ? {
                enterFromClass: 'translate-x-full translate-y-0 translate-z-0 mask-active',
                leaveToClass: 'translate-x-full translate-y-0 translate-z-0 mask-active'
              }
            : {
                enterFromClass: 'opacity-0 mask-active',
                enterActiveClass: 'transition-opacity duration-400 ease-in',
                leaveActiveClass: 'transition-opacity duration-400 ease-in',
                leaveToClass: 'opacity-0 mask-active'
              }
}

// Overlay Panel Pass Through
const overlayPanelPT = {
  root: {
    class: [
      'rounded-md shadow-lg',
      'border-0 dark:border',
      'absolute left-0 top-0 mt-16',
      'z-40 transform origin-center',
      'bg-surface-0 dark:bg-surface-800',
      'text-surface-700 dark:text-surface-0/80',
      'dark:border-surface-700',
      'before:absolute before:-top-[9px] before:-ml-[9px] before:left-[calc(var(--overlayArrowLeft,0)+1.25rem)] z-0',
      'before:w-0 before:h-0',
      'before:border-transparent before:border-solid',
      'before:border-x-[8px] before:border-[8px]',
      'before:border-t-0 before:border-b-surface-300/10 dark:before:border-b-surface-700',
      'after:absolute after:-top-2 after:-ml-[8px] after:left-[calc(var(--overlayArrowLeft,0)+1.25rem)]',
      'after:w-0 after:h-0',
      'after:border-transparent after:border-solid',
      'after:border-x-[0.5rem] after:border-[0.5rem]',
      'after:border-t-0 after:border-b-surface-0 dark:after:border-b-surface-800'
    ]
  },
  content: {
    class: 'p-5 flex item-center'
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0'
  }
}
</script>

<template>
  <Sidebar
    v-model:visible="authStore.showOnboardingScene"
    :pt="sidebarPT"
    position="right"
    :dismissable="!authStore.showOnboardingScene"
    :show-close-icon="!authStore.showOnboardingScene"
  >
    <template #header>
      <div class="flex flex-col items-center relative">
        <img alt="QR Mark Logo" src="@/assets/img/qrmark-logo.svg" width="80" />
        <p class="text-2xl md:text-3xl font-semibold text-font-main subpixel-antialiased">
          Welcome to QR Mark
        </p>
        <span class="text-md md:text-xl font-medium text-font-main subpixel-antialiased">
          We are thrilled to have you onboard!
        </span>
        <span class="text-md md:text-xl font-medium text-font-main subpixel-antialiased mt-12">
          Setup your account
        </span>
      </div>
    </template>
    <template #default>
      <form class="flex flex-col items-center" @submit="onHandleSubmit">
        <div class="flex-col items-center w-full md:w-3/6">
          <Dropdown
            v-model="industry"
            :options="dropdownContext.industry"
            placeholder="Please select your industry*"
            v-bind="industryAttr"
            class="py-1.5"
            scroll-height="250px"
          />
          <span class="text-font-light text-sm">
            This will help us tailor our services to better suit your needs.
          </span>
          <InlineMessage v-show="errors.industry" severity="error">
            {{ errors.industry }}
          </InlineMessage>
        </div>
        <div class="flex-col w-full md:w-3/6 pt-5 relative">
          <div class="flex items-center w-full">
            <InputText
              v-model="companyName"
              placeholder="Company name"
              type="text"
              v-bind="companyNameAttr"
            />
            <LockClosedIcon class="w-5 h-5 ml-2 text-font-main" @click="toggleCompanyName" />
            <OverlayPanel ref="opCompanyName" :pt="overlayPanelPT" append-to="self">
              <p class="text-font-light">
                Your company name will be used only by QR Mark’s customer relations team to reach
                out to you to better understand your requirements. All your details are secure and
                will never be used or shared for any other purpose.
              </p>
            </OverlayPanel>
          </div>
          <InlineMessage v-show="errors.companyName" class="" severity="error">
            {{ errors.companyName }}
          </InlineMessage>
        </div>
        <div class="flex justify-start space-x-2 md:w-3/6 pt-5 relative">
          <span class="flex flex-col items-start">
            <Dropdown
              v-model="countryCode"
              :options="dropdownContext.phoneNumberCode"
              class="w-full py-1.5"
              filter
              optionLabel="country"
              placeholder="Select country"
              v-bind="countryCodeAttr"
            >
              <template #value="slotProps">
                <span v-if="slotProps.value" class="align-sub">
                  <span>{{ slotProps.value.stdCode }}</span>
                </span>
                <span v-else class="align-sub">
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>
            <InlineMessage v-show="errors.stdCode" severity="error">
              {{ errors.stdCode }}
            </InlineMessage>
          </span>
          <span class="flex flex-col w-full items-start">
            <InputNumber
              v-model="phoneNumber"
              :useGrouping="false"
              class="w-full"
              inputId="withoutgrouping"
              placeholder="Phone number"
              v-bind="phoneNumberAttr"
            />
            <InlineMessage v-show="errors.phoneNumber" severity="error">
              {{ errors.phoneNumber }}
            </InlineMessage>
          </span>
          <LockClosedIcon class="w-7 h-7 mt-1.5 text-font-main" @click="togglePhoneNumber" />
          <OverlayPanel ref="opPhoneNumber" :pt="overlayPanelPT" append-to="self">
            <p class="text-font-light">
              Your phone number will be used only by QR Mark’s customer relations team to reach out
              to you to better understand your requirements. All your details are secure and will
              never be used or shared for any other purpose.
            </p>
          </OverlayPanel>
        </div>
        <Button
          type="submit"
          :disabled="!meta.valid"
          class="bg-primary-main w-3/6 mt-5"
          label="Submit"
          outlined
          severity="success"
        />
      </form>
    </template>
  </Sidebar>
  <VTour
    ref="tour"
    :startDelay="2000"
    :steps="steps"
    backdrop
    highlight
    saveToLocalStorage="never"
  />
</template>

<style scoped></style>
