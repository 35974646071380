<script setup lang="ts">
import { usePDF, VuePDF } from '@tato30/vue-pdf'
import { useVerificationStore } from '@/stores/verification'
import { ref, shallowReactive, shallowRef } from 'vue'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon
} from '@heroicons/vue/24/outline'
import GenerateVICard from '@/components/Card/GenerateVICard.vue'
import { useGenerateVerificationImage } from '@/services/verifications/useGenerateVerificationImage'

import Drag, { type DragData } from 'es-drager'
import 'es-drager/lib/style.css'
import ExportPDFCard from '@/components/Card/ExportPDFCard.vue'
import { useExportPDF } from '@/services/verifications/useExportPDF'
import LoadingDots from '@/components/Spinner/LoadingDots.vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { useDiscardPDF } from '@/services/verifications/useDiscardPDF'
import { useBulkOperationStore } from '@/stores/bulkOperation'
import { useCreateBORequest } from '@/services/bulkOperation/useCreateBORequest'

// Props definition
const props = withDefaults(defineProps<{ feature: string }>(), {
  feature: 'verification'
})

// Hooks for toast and confirmation modal
const toast = useToast()
const confirm = useConfirm()

// Store
const verificationStore = useVerificationStore()
const bulkOperationStore = useBulkOperationStore()

// Verification Image state
const verificationImage = shallowRef<string>('')
const pdfRefs = shallowReactive({
  page: 1,
  scale: 1.25,
  top: 0,
  left: 0,
  img_w: 185,
  img_h: 250,
  isLoading: true,
  showVICard: true,
  isAllPage: 0, // -1 is convention for all pages
  showExportPDFCard: false
})

// @tato30/vue-pdf hook
const { pdf, pages, info } = usePDF(
  props.feature === 'bulkOperation'
    ? bulkOperationStore.getPreviewMediaURL
    : verificationStore.getMediaURL
)

// Update pdf loading state.
const afterPDFLoaded = (event: any) => {
  pdfRefs.isLoading = false

  // Set bulk operation sample verification image.
  if (props.feature === 'bulkOperation') verificationImage.value = import.meta.env.VUE_BO_VI_URL
}

// Generate Verification Image endpoint call.
const generateVerificationImage = (
  fileId: number,
  fileName: string,
  customDomain: string,
  selectAllPage: boolean
) => {
  useGenerateVerificationImage(fileId, fileName, customDomain).then(({ data, error, status }) => {
    if (status.value == 200) {
      verificationImage.value = URL.createObjectURL(data.value)
      pdfRefs.showVICard = false
      pdfRefs.showExportPDFCard = true
      verificationStore.setActiveStep(3)
      // -1 is convention for all pages.
      selectAllPage ? (pdfRefs.isAllPage = -1) : pdfRefs.page
    }
    if (status.value == 400) {
      pdfRefs.isLoading = false
      // Error parsing only if responseType is blob
      error.value.text().then((message: any) => {
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: JSON.parse(message).details.detail,
          life: 9000
        })
      })
    }
  })
}

// Bulk Operation request.
const bulkOperationRequest = (boName: string, customDomain: string, selectAllPage: boolean) => {
  console.log(boName, customDomain, selectAllPage)
  const infoData = {
    top: pdfRefs.top,
    left: pdfRefs.left,
    img_w: pdfRefs.img_w,
    img_h: pdfRefs.img_h,
    scaling_factor: pdfRefs.scale,
    selected_page: pdfRefs.isAllPage == -1 ? pdfRefs.isAllPage : pdfRefs.page
  }
  useCreateBORequest(boName, customDomain, infoData).then(({ data, error, status }) => {
    if (status.value === 201) {
      toast.add({
        severity: 'success',
        summary: 'Success Message',
        detail: 'Bulk Operation request send successfully',
        life: 7000
      })
      bulkOperationStore.$reset()
    }
    if (status.value === 400)
      toast.add({
        severity: 'error',
        summary: 'Error Message',
        detail: error.value?.message,
        life: 7000
      })
  })
}

// Disable the cursor when take the verification outside PDF boundaries.
const disableBoundary = ref(false)

// The default size of verification image is width: 185 and height: 250
const onVIChange = (dragData: DragData) => {
  disableBoundary.value = dragData.top < 1 || dragData.left < 1
  pdfRefs.img_w = dragData.width
  pdfRefs.img_h = dragData.height
  pdfRefs.top = dragData.top
  pdfRefs.left = dragData.left
}

// Export pdf endpoint.
const exportPDF = () => {
  const infoData = {
    top: pdfRefs.top,
    left: pdfRefs.left,
    img_w: pdfRefs.img_w,
    img_h: pdfRefs.img_h,
    scaling_factor: pdfRefs.scale,
    selected_page: pdfRefs.isAllPage == -1 ? pdfRefs.isAllPage : pdfRefs.page
  }
  pdfRefs.isLoading = true
  // Send export pdf request. TODO: this should be only for verification feature
  useExportPDF(<number>verificationStore.mediaId, infoData, info.value).then(
    ({ data, error, status }) => {
      if (status.value == 200) {
        let a = document.createElement('a')
        a.href = data.value?.file
        // a.download = data.value?.file.split('/').pop()
        a.target = '_blank'
        a.click()
        a.remove()
        URL.revokeObjectURL(verificationImage.value) // Revoke verification image url.
        verificationStore.$reset()
      }
      if (status.value == 400) {
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: error.value?.message,
          life: 7000
        })
      }
    }
  )
}

// Discard pdf endpoint.
const discardExportPDf = () => {
  // Todo: Discard pdf export endpoint connection.
  confirm.require({
    message: `Are you sure you want to discard this verification?`,
    header: 'Confirmation',
    acceptLabel: 'Confirm',
    rejectLabel: 'Cancel',
    rejectClass: 'bg-red-500 border-red-600 hover:bg-red-600 hover:border-red-600',
    accept: () => {
      useDiscardPDF(<number>verificationStore.getMediaId).then(({ status }) => {
        if (status.value !== 204) {
          toast.add({
            severity: 'error',
            summary: 'Error Message',
            detail: 'Something went wrong. Please reach out to us at support@qrmark.com ',
            life: 7000
          })
        }
        verificationStore.$reset()
      })
    }
  })
}
</script>

<template>
  <div class="flex-col xl:flex xl:flex-row">
    <!-- PDF Viewer-->
    <div class="w-full">
      <div class="pdf-v-head relative">
        <div v-if="pdfRefs.isLoading" class="absolute top-1/2 z-10">
          <LoadingDots />
        </div>
        <!--
          *Relative is used for the verification image
          * !pdfRefs.showExportPDFCard is for handling border in export pdf card.
          -->
        <div
          :class="[
            pdfRefs.isLoading ? 'opacity-30' : 'border-2 border-gray-300 border-dashed',
            !pdfRefs.showExportPDFCard || 'border-2 border-gray-300 border-dashed'
          ]"
          class="relative"
        >
          <VuePDF
            :page="pdfRefs.page"
            :pdf="pdf"
            :scale="pdfRefs.scale"
            class="absolute max-w-screen-md"
            style="overflow: unset"
            @loaded="afterPDFLoaded($event)"
          />
          <Drag
            v-show="verificationImage"
            :boundary="disableBoundary"
            :checkCollision="true"
            :equalProportion="true"
            :height="250"
            :maxHeight="250"
            :maxWidth="185"
            :minHeight="80"
            :minWidth="40"
            :resizable="true"
            :resizeList="['top-left', 'top-right', 'bottom-left', 'bottom-right']"
            :rotatable="false"
            :snap="disableBoundary"
            :width="185"
            @change="onVIChange"
          >
            <img :src="verificationImage" alt="Verification image" height="250" width="185" />
          </Drag>
        </div>
      </div>
      <!-- PDF Zoom, Page Up-Down Tools-->
      <div class="flex space-x-6 my-3">
        <div class="space-x-2 flex items-center">
          <button
            class="button"
            @click="pdfRefs.page = pdfRefs.page > 1 ? pdfRefs.page - 1 : pdfRefs.page"
          >
            <ChevronUpIcon class="h-5 w-5 subpixel-antialiased" />
          </button>
          <span class="text-font-main">{{ pdfRefs.page }} / {{ pages }}</span>
          <button
            class="button"
            @click="pdfRefs.page = pdfRefs.page < pages ? pdfRefs.page + 1 : pdfRefs.page"
          >
            <ChevronDownIcon class="h-5 w-5 subpixel-antialiased" />
          </button>
        </div>
        <div class="flex items-center space-x-2">
          <button
            class="button"
            @click="pdfRefs.scale = pdfRefs.scale > 0.5 ? pdfRefs.scale - 0.25 : pdfRefs.scale"
          >
            <MagnifyingGlassMinusIcon class="w-5 h-5 font-extrabold subpixel-antialiased" />
          </button>
          <span class="text-font-main">{{ pdfRefs.scale * 100 }}%</span>
          <button
            class="button"
            @click="pdfRefs.scale = pdfRefs.scale < 4 ? pdfRefs.scale + 0.25 : pdfRefs.scale"
          >
            <MagnifyingGlassPlusIcon class="w-5 h-5 subpixel-antialiased" />
          </button>
        </div>
      </div>
    </div>

    <div class="ml-0 md:ml-5">
      <!-- Generate Verification Image Card-->
      <GenerateVICard
        v-if="pdfRefs.showVICard"
        :feature="props.feature"
        :total-page="pages"
        @generate-verification-image="generateVerificationImage"
        @bulk-operation-request="bulkOperationRequest"
      />
      <!-- Export pdf card-->
      <ExportPDFCard
        v-if="pdfRefs.showExportPDFCard"
        @export-pdf="exportPDF"
        @discard-export-pdf="discardExportPDf"
      />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.button {
  @apply bg-gray-200 border-r-2 rounded-md px-2 cursor-pointer font-jost text-font-main;
}

.pdf-v-head {
  @apply border-2 border-gray-300 border-solid overflow-scroll h-[40rem] whitespace-nowrap flex-row lg:flex lg:flex-col  items-center;
}
</style>
