import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { QRMarkTailwindPT } from '@/utils/usePassThrough'

import './assets/css/base.css'

// VueJS Tour CSS
import './assets/css/vue-tour-light.css'

import App from './App.vue'
import router from './router'

import * as Sentry from '@sentry/vue'

import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

import Tag from 'primevue/tag'
import Card from 'primevue/card'
import Toast from 'primevue/toast'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Textarea from 'primevue/textarea'
import Fieldset from 'primevue/fieldset'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import InlineMessage from 'primevue/inlinemessage'
import ConfirmDialog from 'primevue/confirmdialog'

// App
const app = createApp(App)

/**
 * QRMarkTailwindPT:
 * QR Mark Tailwind pass through is a centralized theming design system based on primevue components.
 */
// State Management, Router, Toast Service
app
  .use(createPinia()) // Pinia
  .use(router) // Vue Router
  .use(ToastService) // Primevue Toast
  .use(ConfirmationService) // Primevue Confirm
  .use(PrimeVue, { unstyled: true, pt: QRMarkTailwindPT })

// Primevue common components
app
  .component('Tag', Tag)
  .component('Card', Card)
  .component('Toast', Toast)
  .component('Button', Button)
  .component('Dropdown', Dropdown)
  .component('Textarea', Textarea)
  .component('Fieldset', Fieldset)
  .component('InputText', InputText)
  .component('InputNumber', InputNumber)
  .component('ConfirmDialog', ConfirmDialog)
  .component('InlineMessage', InlineMessage)

// Error Handler
app.config.errorHandler = (err, instance, info) => {
  console.log('Error from main', err)
  console.log('Instance from main', instance)
  console.log('Info from main', info)
}

// Sentry configuration
Sentry.init({
  app,
  dsn: 'https://0d0b1707251953dce0f7ee2f5d9d5b45@o13089.ingest.us.sentry.io/4506861514129408',
  environment: import.meta.env.VUE_ENVIRONMENT,
  enabled: import.meta.env.VUE_ENVIRONMENT === 'production',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 0.01, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [import.meta.env.VUE_API_BASE_URL],
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// Mount App
app.mount('#app')
